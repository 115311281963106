import {
  Links,
  Meta,
  Scripts,
  ScrollRestoration,
  useMatches,
  useNavigate,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import UiKitProvider from "../libs/uikit/UiKitProvider";
import theme from "../libs/uikit/theme";
import Container from "~/routes/_client/components/Container";
import {
  Box,
  Button,
  createTheme,
  ScopedCssBaseline,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import Text from "~/components/Text";
import Animation from "~/animation.tsx";
import animationData from "animations/error_animation.json";

export function ErrorBoundary() {
  const navigate = useNavigate();
  const matches = useMatches();
  const error = useRouteError();
  console.error(error);

  captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Maestro</title>
        <Meta />
        <Links />
      </head>
      <body>
        <UiKitProvider>
          <ThemeProvider
            theme={createTheme({
              ...theme,
              typography: {
                fontFamily: "Poppins",
                h2: {
                  fontSize: "20px",
                  fontWeight: 700,
                  fontFamily: "Sora",
                },
                h3: { fontSize: "16px", fontWeight: 700, fontFamily: "Sora" },
                body1: {
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontWeight: 500,
                },
              },
              breakpoints: {
                values: { xs: 0, sm: 524, md: 754, lg: 755, xl: 756 },
              },
              palette: {
                text: { primary: "#141F4D" },
                success: {
                  main: "#8ED081",
                },
                error: { main: "#EF5350" },
                background: {
                  default: "#014361",
                },
              },
              components: {
                MuiScopedCssBaseline: {
                  styleOverrides: {
                    root: {
                      backgroundColor: "#F2F5F7",
                    },
                  },
                },
                MuiButton: {
                  styleOverrides: {
                    root: {
                      backgroundColor: "#374875",
                    },
                    contained: {
                      backgroundColor: "#0709aa",
                      fontFamily: "Sora",
                      fontSize: "16px",
                      textTransform: "none",
                      minWidth: "188px",
                    },
                  },
                },
                MuiAvatar: {
                  styleOverrides: {
                    root: {
                      backgroundColor: "#E6E9EA",
                    },
                  },
                },
                MuiAlert: {
                  styleOverrides: {
                    message: {
                      fontFamily: "Poppins",
                      fontSize: "12px",
                    },
                  },
                },
              },
            })}
          >
            <ScopedCssBaseline sx={{ minHeight: "100svh", minWidth: "100svw" }}>
              <Container>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  spacing={5}
                  sx={{
                    height: "100%",
                    paddingTop: { xs: 3, md: 10 },
                  }}
                >
                  <Box sx={{ width: "30%" }}>
                    <Animation animationData={animationData} loop={false} />
                  </Box>
                  {/*<ErrorRounded*/}
                  {/*  color={"error"}*/}
                  {/*  sx={(theme) => ({*/}
                  {/*    width: theme.spacing(15),*/}
                  {/*    height: theme.spacing(15),*/}
                  {/*  })}*/}
                  {/*/>*/}
                  <Stack spacing={2} alignItems={"center"} marginBottom={10}>
                    <Typography variant={"h2"} align={"center"}>
                      <Text textKey={"error_page_title"} />
                    </Typography>
                    <Typography
                      variant={"body1"}
                      align={"center"}
                      maxWidth={"400px"}
                    >
                      <div>
                        <Text textKey={"error_page_subtitle_line1"} />
                      </div>
                      <div>
                        <Text textKey={"error_page_subtitle_line2"} />
                      </div>
                      <Box pt={2}>
                        <Text textKey={"error_page_subtitle_line3"} />
                      </Box>
                    </Typography>
                  </Stack>
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      const isOfficePage = matches.find(
                        ({ id }) => id === "routes/_office"
                      );
                      if (isOfficePage) navigate("/");
                      const clientPage = matches.find(
                        ({ id }) =>
                          id ===
                          "routes/_client.identite-bancaire.$bankDetailsRequestReference"
                      );
                      if (clientPage) {
                        navigate(
                          `/identite-bancaire/${clientPage.params.bankDetailsRequestReference}/confirmer`
                        );
                      }
                    }}
                  >
                    <Text textKey={"error_page_button"} />
                  </Button>
                </Stack>
              </Container>
            </ScopedCssBaseline>
          </ThemeProvider>
        </UiKitProvider>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}
