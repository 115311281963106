import { withSentry } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
} from "@remix-run/react";
import { LinksFunction, MetaFunction } from "@remix-run/node";
import rootCss from "./global.css?url";
import SnackbarProvider from "./components/snackbar-provider";
import rootLoader from "~/root-loader";

export { default as loader } from "./root-loader";
export { ErrorBoundary } from "./error";

export const meta: MetaFunction = () => {
  return [{ title: "Maestro" }, { name: "description", content: "Maestro" }];
};

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: rootCss },
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap",
  },
];

// noinspection JSUnusedGlobalSymbols
export function Layout({ children }: { children: React.ReactNode }) {
  const rootData = useRouteLoaderData<typeof rootLoader>("root");
  const SENTRY_DSN = rootData?.ENV.SENTRY_DSN;
  const POSTHOG_ID = rootData?.ENV.POSTHOG_ID;
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="build" content="0190176b-ee85-742d-b915-01dac70c7119" />
        <title>Maestro</title>
        <Meta />
        <Links />
        {SENTRY_DSN ? (
          <script
            id="sentry-dsn-env-init"
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify({ SENTRY_DSN })}`,
            }}
          />
        ) : null}
        {POSTHOG_ID ? (
          <script
            id="posthod-init"
            dangerouslySetInnerHTML={{
              __html: `
                !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host.replace(".i.posthog.com","-assets.i.posthog.com")+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys getNextSurveyStep onSessionId setPersonProperties".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
              posthog.init('${POSTHOG_ID}',{api_host:'https://eu.i.posthog.com', person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
              })
            `,
            }}
          />
        ) : null}
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return (
    <SnackbarProvider>
      <Outlet />
    </SnackbarProvider>
  );
}

export default withSentry(App);
