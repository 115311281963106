import { ReactNode, useEffect, useState } from "react";
import { useLoaderData } from "@remix-run/react";
import rootLoader from "~/root-loader";
import { Alert, Snackbar } from "@mui/material";
import Text from "~/components/Text";
import { SerializeFrom } from "@remix-run/node";

export default function ({ children }: { children: ReactNode }) {
  const { snackbar } = useLoaderData<typeof rootLoader>();
  const [snackbarToDisplay, updateSnackbar] = useState<
    SerializeFrom<typeof rootLoader>["snackbar"] | null
  >();

  useEffect(() => {
    if (!snackbar) return;
    updateSnackbar(snackbar);
  }, [snackbar]);

  return (
    <>
      {snackbarToDisplay && (
        <Snackbar
          open={!!snackbarToDisplay}
          autoHideDuration={6000}
          onClose={() => updateSnackbar(null)}
        >
          <Alert
            onClose={() => updateSnackbar(null)}
            severity={snackbarToDisplay.type}
            variant="standard"
            sx={{ width: "100%" }}
          >
            <Text textKey={snackbarToDisplay.textContent} />
          </Alert>
        </Snackbar>
      )}
      {children}
    </>
  );
}
